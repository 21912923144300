import {useEffect, useState} from "react";
import moment from "moment";


function Timer({timeout, handleTimeout}) {
    const initDuration =  moment.unix(timeout).diff(moment.utc())
    const [timeLeft, setTimeLeft] = useState(initDuration)
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(moment.unix(timeout).diff(moment.utc()))
        }, 1000);
        return () => clearInterval(interval);
    }, [timeout])
    useEffect(() => {
        if (timeLeft <= 0) {
            handleTimeout()
        }
    }, [timeLeft, handleTimeout])
    return (
        moment.utc(timeLeft).format("HH:mm:ss")
    )
}

export default Timer