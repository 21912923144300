import {useContext} from "react";
import {UserContext} from "../../App";
import docsIcon from '../../icons/docs.svg'
import defaultUserIcon from '../../icons/user-default.svg'
import classes from "./Header.module.css";
import Docs from "../Docs/Docs";

function Header({setContent}) {
    const {user} = useContext(UserContext);
    const showName = () => {
        let n = user.name
        if (n.length > 16) {
            return n.substring(0, 16) + '...'
        }
        return  n
    }
    return (
        <header className={classes.header}>
            <div className="row">
                <div className="col-8">
                    <img className="mx-2" height="25" width="25" src={user.photo_url === '' ? defaultUserIcon : user.photo_url}
                         alt={user.name}/>
                    {showName()}
                </div>
                <div className="col-4 text-end">
                    <span className={classes.docsButton} onClick={() => setContent({key: null, component: <Docs/>})}>
                        Docs
                        <img className={classes.docsImg} src={docsIcon} alt="Documentation"/>
                    </span>
                </div>
            </div>
        </header>
    )
}

export default Header;