import lightningIcon from "../../icons/lightning.svg";
import {useContext, useEffect} from "react";
import {UserContext} from "../../App";
import vipOnIcon from '../../icons/vipOn.svg'
import vipOffIcon from '../../icons/vipOff.svg'
import wheelIcon from '../../icons/wheel-button.svg'
import classes from "./Power.module.css";
import Exchange from "../Exchange";
import LuckyWheel from "../LuckyWheel/LuckyWheel";

function Power({setContent}) {
    const {user} = useContext(UserContext);
    useEffect(()=> {}, [user])
    return (
        <div className="row justify-content-center position-relative">
            <div className={`${classes.circle} text-center`} onClick={() => {setContent({key: null, component: <Exchange/>})}}>
                <img className={classes.vipIcon} height="30" src={user.power > 1000 ? vipOnIcon : vipOffIcon}
                     alt="VIP status gives x2 rewards"/>
                <div className={classes.powerPoints}>
                    {user.power}
                    <span><img height="21" src={lightningIcon} alt="power"/></span>
                </div>
                <span className={classes.exchange}>EXCHANGE</span>
            </div>
            <div onClick={() => setContent({component: <LuckyWheel/>})} className={classes.wheelButton}>
                <img src={wheelIcon} alt="Lucky wheel"/>
                <span>{user.lucky_tickets}</span>
            </div>
        </div>
    )
}

export default Power