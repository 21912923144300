import {CopyToClipboard} from "react-copy-to-clipboard";
import {useContext} from "react";
import {UserContext} from "../App";
import api from "../api";
import PageableTable from "./PagebleTable/PageableTable";
import {toast} from "react-toastify";
import defaultUserIcon from "../icons/user-default.svg";

function Referrals() {
    const {user} = useContext(UserContext);

    const fetcher = (page) => {
        return api.referrals(page).then((response) => {
            if (response.status === 200) {
                const data = response.data.list.map((fields) => {
                    return {
                        user: (
                            <>
                                <img height="20" className="mx-2"
                                     src={fields.photo_url === '' ? defaultUserIcon : fields.photo_url}
                                     alt={fields.name}/>
                                {fields.name}
                            </>
                        ),
                        power: fields.power,
                        referrals: fields.referrals,
                    }
                })
                return {data, total: response.data.total_l1}
            }
            if (response.data.message !== undefined) {
                alert(response.data.message)
            }
        })
    }
    const personalLink = 'https://t.me/' + process.env.REACT_APP_BOT_USERNAME + '?start=' + user.id
    const shareTxt = "Join to the Power Station and get a TON rewards"
    return (
        <div className="container">
            <div className="text-block text-center">
                <p>Invite your friends and get more additional rewards</p>
                <p><strong>{personalLink}</strong></p>
                <div className="row py-3">
                    <div className="col-6">
                        <a href={"https://telegram.me/share/url?url=" + personalLink + "&text=" + encodeURIComponent(shareTxt)}>
                            <button className="btn btn-outline-primary">
                                Share
                            </button>
                        </a>
                    </div>
                    <div className="col-6">
                        <CopyToClipboard text={personalLink} onCopy={() => toast('Copied')}>
                            <button className="btn btn-outline-primary">Copy</button>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
            <div className="row">
                <PageableTable fetcher={fetcher} columns={['Name', 'Power', 'Referrals']}/>
            </div>
        </div>
    );
}

export default Referrals;