import moment from "moment";


const utils = {
    toDateTime(timestamp) {
        return moment.utc(timestamp * 1000).format('DD.MM.YY HH:mm')
    },
    nanoToTON(nano, decimals = 8) {
        let n = Number(nano / 1e9).toFixed(decimals)
        return this.numberToPlainText(n)
    },
    tonToNANO(ton) {
        return Number(ton * 1e9)
    },
    makeTxLink(hash) {
        return "https://tonviewer.com/transaction/" + hash
    },
    numberToPlainText(num) {
        return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
            function (a, b, c, d, e) {
                return e < 0
                    ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
                    : b + c + d + Array(e - d.length + 1).join(0);
            });
    }
}

export default utils