import {Wheel} from "react-custom-roulette";
import {useContext, useState} from "react";
import WheelArrowImg from "./wheel-arrow.png"
import classes from "./LuckyWheel.module.css";
import api from "../../api";
import {toast} from "react-toastify";
import {UserContext} from "../../App";
import moment from "moment/moment";

function LuckyWheel() {
    const {user, reload} = useContext(UserContext);
    const [mustSpin, setMustSpin] = useState(false);
    const [spinLock, setSpinLock] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [buyLock, setBuyLock] = useState(false);

    const handleSpinClick = () => {
        if (user.lucky_tickets === 0) {
            toast(`Not enough lucky tickets`)
            return
        }
        if (spinLock) {
            return
        }
        setSpinLock(true)
        api.spin().then((response) => {
            if (response.status === 200) {
                setPrizeNumber(response.data.sector - 1)
                setMustSpin(true)
            }
        }).catch((e) => {
            if (e.response !== undefined && e.response.data.message !== undefined) {
                toast(`${e.response.data.message}`)
            } else {
                toast(`Something went wrong with server connection`)
            }
        })
    }
    const handleBuyClick = () => {
        if (buyLock) {
            return
        }
        if (user.balance === 0) {
            toast(`Not enough balance`)
            return
        }
        setBuyLock(true)
        api.buyLuckyTicket().then((response) => {
            if (response.status === 200) {
                toast(`You have bought a lucky ticket`)
                reload(moment.now())
            }
        }).catch((e) => {
            if (e.response !== undefined && e.response.data.message !== undefined) {
                toast(`${e.response.data.message}`)
            } else {
                toast(`Something went wrong with server connection`)
            }
        }).finally(() => {
            setBuyLock(false)
        })
    }

    const data = [
        {option: '+ 1 Power', style: {backgroundColor: '#2d3daa', textColor: 'white'}},
        {option: '+ 5 Power', style: {backgroundColor: '#2738a3', textColor: 'white'}},
        {option: '+ 10 Power', style: {backgroundColor: '#1e2e97', textColor: 'white'}},
        {option: '+ 0.1 TON', style: {backgroundColor: '#101b67', textColor: 'white'}},
        {option: '+ 50 Power', style: {backgroundColor: '#101e78', textColor: 'white'}},
        {option: '+ 1 TON', style: {backgroundColor: '#071045', textColor: 'white'}},
        {option: '+ 10 TON', style: {backgroundColor: '#040a37', textColor: 'white'}},
        {option: '+ 50 TON', style: {backgroundColor: '#040a32', textColor: 'white'}},
    ]

    return (
        <div className="container">
            <h5 className="text-center">Lucky Wheel</h5>
            <div className="d-flex justify-content-center">
                <Wheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={data}
                    radiusLineColor={'rgb(52,56,76)'}
                    innerBorderColor={'rgb(52,56,76)'}
                    outerBorderColor={'rgb(52,56,76)'}
                    pointerProps={{src: WheelArrowImg}}
                    onStopSpinning={() => {
                        setMustSpin(false)
                        setSpinLock(false)
                        reload(moment.now())
                        toast(data[prizeNumber].option)
                    }}
                />
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <button className={classes.spinButton} onClick={handleSpinClick}>SPIN</button>
                </div>
            </div>

            <h5 className="text-center">List of rewards</h5>
            <ul>
                <li>1 Power</li>
                <li>5 Power</li>
                <li>10 Power</li>
                <li>50 Power</li>
                <li>0.1 TON</li>
                <li>1 TON</li>
                <li>50 TON (Jackpot)</li>
            </ul>
            <p>To get a <strong>free spin</strong> you have to invite a new friend. Or you can buy additional tickets
                for spin lucky
                wheel.</p>
            <div className="row text-center py-3">
                <div className="col-4">
                    <button className={classes.buyButton} onClick={handleBuyClick}>BUY</button>
                </div>
                <div className="col-8 text-center align-self-center">
                    1 SPIN = 0.05 TON
                </div>
            </div>

        </div>
    );
}

export default LuckyWheel;