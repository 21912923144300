import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import {SDKProvider, initMiniApp} from '@tma.js/sdk-react';
import App from "./App";

if (process.env.REACT_APP_DEV_AUTH === undefined || process.env.REACT_APP_DEV_AUTH === false) {
    const [miniApp] = initMiniApp();
    miniApp.setHeaderColor('#202431');
    miniApp.setBgColor('#202431');
}

ReactDOM.createRoot(document.getElementById("root")).render(
    <SDKProvider acceptCustomStyles debug>
        <App/>
    </SDKProvider>
);
