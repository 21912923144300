import Rewards from "./components/Rewards";
import {createContext, useEffect, useState} from "react";
import api from "./api";
import utils from "./utils/utils";
import Header from "./components/Header/Header";
import Power from "./components/Power/Power";
import Nav from "./components/Nav/Nav";
import Deposits from "./components/Deposits/Deposits";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import updateIcon from "./icons/refresh.svg";
import moment from "moment";
import Loader from "./components/Loader";

export const UserContext = createContext(null);

function App() {
    const [loaded, setLoaded] = useState(false);
    const [reloader, reload] = useState(moment.now)
    const [content, setContent] = useState({
        key: 'rewards',
        component: <Rewards/>
    })
    const [user, setUser] = useState({
        id: 0,
        balance: 0,
        power: 0,
        name: '',
        photo_url: '',
    })
    useEffect(() => {
        const fetch = async () => {
            const response = await api.profile()
            setUser(response.data)
        }
        fetch().finally(() => {
            setLoaded(true)
        })
    }, [reloader])

    if (!loaded) {
        return (
            <div className="app">
                <div className="container">
                    <p className="py-3"></p>
                    <Loader/>
                </div>
            </div>
        )
    }

    return (
        <div className="app">
            <div className="container">
                <UserContext.Provider value={{user, setUser, reload}}>
                    <Header setContent={setContent}/>
                    <Power setContent={setContent}/>
                    <div className="text-center py-3">
                        <span onClick={() => setContent({key: null, component: <Deposits/>})}>
                            Balance: <span><strong>{utils.nanoToTON(user.balance)}</strong></span> TON [deposit]
                        </span>
                        <span onClick={() => { setLoaded(false); reload(moment.now())}} style={{paddingLeft: '20px'}}>
                            <img height="16" src={updateIcon} alt="update balance"/>
                    </span>
                    </div>

                    <Nav content={content} setter={setContent}/>
                    <hr/>
                    <div className="row">
                        <div className="col-12">
                            {content.component}
                        </div>
                    </div>
                </UserContext.Provider>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </div>
        </div>
    );
}

export default App;
