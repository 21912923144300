import api from "../api";
import PageableTable from "./PagebleTable/PageableTable";
import defaultUserIcon from "../icons/user-default.svg";
import utils from "../utils/utils";

function Top() {
    const fetcher = (page) => {
        return api.top(page).then((response) => {
            if (response.status === 200) {
                const data = response.data.map((fields) => {
                    let name = fields.name
                    if (name.length > 12) {
                       name = name.substring(0, 12) + "..."
                    }
                    let reward = utils.nanoToTON(fields.rewards, 4)
                    return {
                        place: '#' + fields.place,
                        user: (
                            <>
                                <img height="20" className="mx-2"
                                     src={fields.photo_url === '' ? defaultUserIcon : fields.photo_url}
                                     alt={fields.name}/>
                                {name}
                            </>
                        ),
                        power: fields.power,
                        rewards: reward + ' TON',
                    }
                })
                return {data, total: data.length}
            }
            if (response.data.message !== undefined) {
                alert(response.data.message)
            }
        })
    }

    return (
        <div className="row">
            <PageableTable fetcher={fetcher} columns={['', 'Name', 'Power', 'Rewards']}/>
        </div>
    );
}

export default Top;