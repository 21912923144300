import api from "../api";
import PageableTable from "./PagebleTable/PageableTable";
import utils from "../utils/utils";
import {toast} from "react-toastify";
import {useState} from "react";

function Rewards() {
    const [previousRewardsPool, setPreviousRewardsPool] = useState(0)

    const fetcher = (page) => {
        return api.rewards(page).then((response) => {
            if (response.status === 200) {
                const data = response.data.items.map((fields) => {
                    return {
                        amount: <><span className="reward-amount">+{utils.nanoToTON(fields.amount)}</span> TON</>,
                        source: fields.source_id === null ? 'system' : 'referrals',
                        created_at: utils.toDateTime(fields.created_at),
                    }
                })
                return {data, total: response.data.total}
            }
        }).catch((e) => {
            if (e.response !== undefined && e.response.data.message !== undefined) {
                toast(`${e.response.data.message}`)
            } else {
                toast(`Something went wrong with server connection`)
            }
        })
    }

    api.getPreviousRewardsPool().then((response) => {
        if (response.status === 200) {
            const rewardsPool = response.data
            setPreviousRewardsPool(rewardsPool)
        }
    }).catch((e) => {
        if (e.response !== undefined && e.response.data.message !== undefined) {
            toast(`${e.response.data.message}`)
        } else {
            toast(`Something went wrong with server connection`)
        }
    })

    return (
        <div className="container">
            <div className="text-block text-center">
                <strong>More power More rewards!</strong><br/>
                Rewards distribute every day<br/>
                Previous rewards pool: <strong>{utils.nanoToTON(previousRewardsPool)} TON </strong>
            </div>
            <div className="row">
                <PageableTable fetcher={fetcher} columns={['Amount', 'Source', 'Date']}/>
            </div>
        </div>
    );
}

export default Rewards;