import api from "../api";
import {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {UserContext} from "../App";
import Timer from "./Timer";
import moment from "moment";
import Loader from "./Loader";

function Claimer() {
    const [loaded, setLoaded] = useState(false);
    const {reload} = useContext(UserContext);
    const [claimStatus, setClaimStatus] = useState(null)
    const fetchClaimStatus = () => {
        api.claimStatus().then((response) => {
            if (response.status === 200) {
                const result = response.data
                setClaimStatus(result)
            }
        }).catch((e) => {
            if (e.response !== undefined && e.response.data.message !== undefined) {
                toast(`${e.response.data.message}`)
            } else {
                toast(`Something went wrong with server connection`)
            }
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchClaimStatus()
    }, [])

    const claim = () => {
        api.claim().then((response) => {
            if (response.status === 200) {
                toast('Claimed! you have got power')
                fetchClaimStatus()
                reload(moment.now())
            }
        }).catch((e) => {
            if (e.response !== undefined && e.response.data.message !== undefined) {
                toast(`${e.response.data.message}`)
            } else {
                toast(`Something went wrong with server connection`)
            }
        })
    }

    if (!loaded) {
        return <Loader/>
    }

    return (
        <div className="row text-center p-2">
            <div className="col-8">
                <div className="my-2">
                    {claimStatus.available && 'Claim is available (Free Power)'}
                    {!claimStatus.available &&
                        <p>Time to next claim: <Timer timeout={claimStatus.available_at} handleTimeout={fetchClaimStatus}/></p>
                    }
                </div>
            </div>
            <div className="col-4">
                {claimStatus.available && <button className="btn btn-success" onClick={claim}>Claim</button>}
                {!claimStatus.available && <button className="btn" disabled>Claim</button>}
            </div>
        </div>
    )
}

export default Claimer