import {useContext, useState} from "react";
import {toast} from "react-toastify";
import api from "../api";
import {UserContext} from "../App";
import moment from "moment";

function Exchange() {
    const rate = 100
    const {reload} = useContext(UserContext);
    const [form, setForm] = useState({amount: 1, power: rate})
    const submit = (e) => {
        e.preventDefault()
        if (form.amount < 1) {
            toast('Minimal amount is 1 TON')
            return
        }
        api.swap(form.power).then((response) => {
            if (response.status === 200) {
                toast('Your power was increased!')
                reload(moment.now())
            }
        }).catch((e) => {
            if (e.response !== undefined && e.response.data.message !== undefined) {
                toast(`${e.response.data.message}`)
            } else {
                toast(`Something went wrong with server connection`)
            }
        })
    }
    const handleAmountChange = (event) => {
        let {value} = event.target
        const power = value * rate
        setForm({amount: value, power})
    }
    const handlePowerChange = (event) => {
        let {value} = event.target
        const amount = value / rate
        setForm({amount, power: value})
    }
    return (
        <div className="container">
            <h4 className="text-center">Exchange</h4>
            <div className="text-block p-4">
                <div className="info-block">
                    <p>Exchange rate is 1 TON = {rate} Power. Min exchange is 1 TON.</p>
                    Bonuses:
                    <ul>
                    <li>1k power = +5% bonus</li>
                        <li>5k power = +10% bonus</li>
                        <li>10k power = +15% bonus</li>
                        <li>50k power = +30% bonus</li>
                    </ul>
                </div>
                <form data-bs-theme="dark" onSubmit={submit}>
                    <div className="mb-3">
                        <label className="form-label">Amount:</label>
                        <input name="amount" value={form.amount} placeholder="0.0" type="number"
                               className="form-control" onChange={handleAmountChange}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Power:</label>
                        <input name="amount" value={form.power} placeholder="0" type="number"
                               className="form-control" onChange={handlePowerChange}/>
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary">Exchange</button>
                    </div>
                </form>
            </div>
        </div>

    );
}

export default Exchange;