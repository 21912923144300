import {useEffect, useState} from "react";
import classes from "./PageableTable.module.css";
import Loader from "../Loader";

function PageableTable({columns, fetcher, perPage = 10}) {
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)

    const fetch = async () => {
        const result = await fetcher(page)
        if (result !== undefined ) {
            setTotal(result.total)
            setData(result.data)
        }
    }
    useEffect(() => {
        fetch().finally(() => {
            setLoaded(true)
        })
    }, [page])
    const maxPage = Math.ceil(total / perPage)
    if (!loaded) {
        return <Loader/>
    }
    return (
        <div>
            <table className={`table ${classes.table}`}>
                <thead>
                <tr>
                    {
                        columns && columns.map((column, index) => {
                            return (
                                <th key={index}>{column}</th>
                            )
                        })
                    }
                </tr>
                </thead>
                <tbody>
                {(data === undefined || data.length === 0) ?
                    <tr>
                        <td key="0" className="text-center" colSpan={columns.length}>
                            No data yet
                        </td>
                    </tr>
                    : ''
                }
                {
                    (data === undefined || data.length > 0) && data.map((fields, index) => {
                        return (
                            <tr key={index}>
                                {
                                    Object.values(fields).map((field, index) => {
                                        return <td key={index}>{field}</td>
                                    })
                                }
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            {total > 10 &&
                <ul className="justify-content-center pagination">
                    <li className={`page-item ${classes.pageItem}`} onClick={() => setPage(1)}>
                        <button className="page-link">
                            <span>&laquo;</span>
                        </button>
                    </li>
                    {
                        (page > 1) &&
                        <li className={`page-item ${classes.pageItem}`} onClick={() => setPage(page - 1)}>
                            <button className="page-link">
                                {page - 1}
                            </button>
                        </li>
                    }
                    {
                        <li className={`page-item ${classes.pageItem} ${classes.activePage}`}>
                            <button className="page-link">
                                {page}
                            </button>
                        </li>
                    }
                    {
                        total > (page) * perPage &&
                        <li className={`page-item ${classes.pageItem}`} onClick={() => setPage(page + 1)}>
                            <button className="page-link">
                                {page + 1}
                            </button>
                        </li>
                    }
                    <li className={`page-item ${classes.pageItem}`} onClick={() => setPage(maxPage)}>
                        <button className="page-link" tabIndex="0">
                            <span>&raquo;</span>
                        </button>
                    </li>
                </ul>
            }
        </div>
    )
}

export default PageableTable