import classes from "./Docs.module.css";
import {useState} from "react";

function Docs() {
    const [texts, setTexts] = useState([
        {
            title: 'About game',
            text:
                <>
                    Power station - its economical game with possibility to earn real money.
                    Every day game distributes rewards to each user who has a power.
                    The more power user has, the more rewards it gets. Rewards distribute in TON assets according to
                    share of user power.
                </>,
            open: false,
        },
        {
            title: 'How to increase power ?',
            text:
                <>
                    To increase your power you should exchange your TON to power with fixed rate.
                    Exchange page you can visit by the click on circle in center.
                    Also, you can spend your rewards or make a new deposit, it's a faster way.
                </>,
            open: false,
        },
        {
            title: 'Free power',
            text:
                <>
                    First of all, don't forget to claim free rewards every time when it's available.
                    You can claim it on 'Tasks' page. Also, you can make another tasks if they exist.
                </>,
            open: false,
        },
        {
            title: 'Daily rewards distribution',
            text:
                <>
                    Rewards distribute every day according to user power share. It goes to your TON balance directly.
                    Amount depends on rewards pool balance, it is not equals all the time.
                </>,
            open: false,
        },
        {
            title: 'Withdrawals',
            text:
                <>
                    You can withdraw your TON balance at any moment. But you can't create a new order to withdraw
                    when you have previous one in pending status. Be careful when make withdrawal, check your address
                    and remember that you can not use comment/memo, because it uses by payment system to mark your
                    withdrawal.
                </>,
            open: false,
        },
        {
            title: 'How much I can earn ?',
            text:
                <>
                    It depends on your power and number of referrals. The more referrals you have the
                    greater your rewards. Anyway you can also earn a lot without referrals, just keep you smart
                    balance between power and TON balance.
                </>,
            open: false,
        },
        {
            title: 'Referral rewards',
            text:
                <>
                    You receive TON rewards from each exchange of your friends (referrals) and also from your
                    sub-referrals.
                    <ul>
                        <li>20% from referrals (1 LVL)</li>
                        <li>5% from sub-referrals (2 LVL)</li>
                    </ul>
                    So, if your friend exchange 100 TON, you immediately receive 20 TON on your balance.
                </>,
            open: false,
        },
        {
            title: 'VIP status',
            text:
                <>
                    This status gives you double daily rewards. To rich VIP status you need to have more than 1k power.
                </>,
            open: false,
        },
        {
            title: 'Rewards Pool',
            text:
                <>
                    Rewards pool fills from exchanges, it takes 70% from each exchange. Also pool fills from system fees
                    and partners rewards. Amount of rewards pool is dynamic, depends on daily activity.
                </>,
            open: false,
        },
    ])


    const collapse = (index) => {
        for (let i = 0; i < texts.length; i++) {
            if (i === index) {
                texts[i].open = !texts[i].open
            }
        }
        setTexts(result => [...texts])
    }

    return (
        <>
            {texts.map(({title, text, open}, index) => {
                return <div key={index} className={classes.textBlock} onClick={() => collapse(index)}>
                    <div className={`${classes.title} row`}>
                        <div className="col-10">
                            <div>{title}</div>
                        </div>
                        <div className="col-2">{open ? '-' : '+'}</div>
                    </div>
                    {open && <div>{text}</div>}
                </div>
            })}
        </>
    )
}

export default Docs