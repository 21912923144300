import {useContext} from "react";
import {UserContext} from "../../App";
import {toast} from "react-toastify";
import {CopyToClipboard} from "react-copy-to-clipboard";
import api from "../../api";
import utils from "../../utils/utils";
import PageableTable from "../PagebleTable/PageableTable";
import classes from "./Deposits.module.css";

function Deposits() {
    const depositAddress = process.env.REACT_APP_DEPOSIT_ADDRESS
    const {user} = useContext(UserContext);

    const fetcher = (page) => {
        return api.deposits(page).then((response) => {
            if (response.status === 200) {
                const data = response.data.items.map((fields) => {
                    return {
                        tx_link: <a href={utils.makeTxLink(fields.tx_hash)} target="_blank" rel="noreferrer">open</a>,
                        amount: <>{utils.nanoToTON(fields.amount)} TON</>,
                        created_at: utils.toDateTime(fields.created_at),
                    }
                })
                return {data, total: response.data.total}
            }
        }).catch((e) => {
            if (e.response !== undefined && e.response.data.message !== undefined) {
                toast(`${e.response.data.message}`)
            } else {
                toast(`Something went wrong with server connection`)
            }
        })
    }
    return (
        <div className="container">
            <h4 className="text-center">Make deposit</h4>
            <div className="text-block p-4">
                <div className="info-block">
                    Make sure that you copy the right address and specify a comment when make a deposit, otherwise you will lose your assets.
                </div>
                <div className="py-2">
                    <div>Deposit address: (click to copy)</div>
                    <CopyToClipboard text={depositAddress} onCopy={() => toast('Copied')}>
                        <button className="btn btn-dark"><span className={classes.depositAddress}>{depositAddress}</span></button>
                    </CopyToClipboard>
                </div>
                <div className="py-2">
                    <div>Comment (tag/memo):</div>
                    <CopyToClipboard text={user.id} onCopy={() => toast('Copied')}>
                        <button className="btn btn-dark">{user.id}</button>
                    </CopyToClipboard>
                </div>
            </div>
            <div className="row p-2">
                <h4 className="text-center">History</h4>
                <PageableTable fetcher={fetcher} columns={['Tx', 'Amount', 'Date']}/>
            </div>
        </div>
    );
}

export default Deposits;