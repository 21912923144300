import Claimer from "./Claimer";

function Tasks() {
    return (
        <div className="container">
            <div className="text-block">
                <Claimer/>
            </div>
            <div className="text-block p-4">
                Invite friends and get a lucky wheel ticket
            </div>
        </div>
    );
}

export default Tasks;