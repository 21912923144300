import PageableTable from "./PagebleTable/PageableTable";
import api from "../api";
import utils from "../utils/utils";
import {toast} from "react-toastify";
import {useContext, useState} from "react";
import {UserContext} from "../App";
import moment from "moment/moment";

function Withdrawals() {
    const {reload} = useContext(UserContext);
    const [withdrawal, setWithdrawal] = useState({address: '', amount: 0})
    const [rewardsReloader, setRewardsReloader] = useState(0)
    const getStatus = (statusCode) => {
        return statusCode === 1 ? 'paid' : 'pending'
    }
    const fetcher = (page) => {
        return api.withdrawals(page).then((response) => {
            if (response.status === 200) {
                const data = response.data.items.map((fields) => {
                    return {
                        tx_link: <a href={utils.makeTxLink(fields.tx_hash)} target="_blank" rel="noreferrer">open</a>,
                        amount: <> {utils.nanoToTON(fields.amount)} TON</>,
                        status: getStatus(fields.status),
                        created_at: utils.toDateTime(fields.created_at),
                    }
                })
                return {data, total: response.data.total}
            }
        }).catch((e) => {
            if (e.response !== undefined && e.response.data.message !== undefined) {
                toast(`${e.response.data.message}`)
            } else {
                toast(`Something went wrong with server connection`)
            }
        })
    }
    const submit = (e) => {
        e.preventDefault()
        if (withdrawal.amount < 1) {
            toast('Minimal amount is 1 TON')
            return
        }
        if (withdrawal.address.length > 50 || withdrawal.address.length < 45) {
            toast('invalid address')
            return
        }
        api.withdraw(utils.tonToNANO(withdrawal.amount), withdrawal.address).then((response) => {
            if (response.status === 200) {
                toast('Withdrawal order was created')
                setWithdrawal({address: '', amount: 0})
                setRewardsReloader(moment.now())
                reload(moment.now())
            }
        }).catch((e) => {
            if (e.response !== undefined && e.response.data.message !== undefined) {
                toast(`${e.response.data.message}`)
            } else {
                toast(`Something went wrong with server connection`)
            }
        })
    }
    const handleWithdrawalChange = (event) => {
        const {name, value} = event.target;
        setWithdrawal((withdrawal) => ({...withdrawal, [name]: value}))
    };
    return (
        <div className="container">
            <h4 className="text-center">Make Withdrawal</h4>
            <div className="text-block p-4">
                <div className="info-block">
                    Make sure that you specify your TON address, otherwise you will lose your assets. Minimal amount is
                    1 TON. You can have only one pending withdrawal.
                </div>
                <form data-bs-theme="dark" onSubmit={submit}>
                    <div className="mb-3">
                        <label className="form-label">Your TON address:</label>
                        <input name="address" value={withdrawal.address} placeholder="your_ton_address" type="text"
                               className="form-control" onChange={handleWithdrawalChange}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Amount:</label>
                        <input name="amount" value={withdrawal.amount} placeholder="0.0" type="number"
                               className="form-control" onChange={handleWithdrawalChange}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Fee:</label>
                        <input type="number" className="form-control" value="0.1" disabled/>
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary">Withdraw</button>
                    </div>
                </form>
            </div>
            <div className="row">
                <h4 className="text-center">History</h4>
                <PageableTable key={rewardsReloader} fetcher={fetcher} columns={['Tx', 'Amount', 'Status', 'Date']}/>
            </div>
        </div>

    );
}

export default Withdrawals;