import rewardsIcon from '../../icons/rewards.svg'
import friendsIcon from '../../icons/friends.svg'
import tasksIcon from '../../icons/tasks.svg'
import topIcon from '../../icons/top.svg'
import paymentsIcon from '../../icons/withdrawals.svg'
import classes from "./Nav.module.css";
import Rewards from "../Rewards";
import Referrals from "../Referrals";
import Tasks from "../Tasks";
import Withdrawals from "../Withdrawals";
import Top from "../Top";

function NavItem({value, onClick, active}) {
    return (
        <div className={`${classes.navItem} ${active ? classes.active : ''}`} onClick={onClick}>
            <img height="25" src={value.icon} alt={value.title}/>
            <div>{value.title}</div>
        </div>
    )
}

function Nav({content, setter}) {
    const tabs = [
        {key: 'rewards', title: 'Rewards', icon: rewardsIcon, component: <Rewards/>},
        {key: 'friends', title: 'Friends', icon: friendsIcon, component: <Referrals/>},
        {key: 'tasks', title: 'Tasks', icon: tasksIcon, component: <Tasks/>},
        {key: 'top', title: 'Top', icon: topIcon, component: <Top/>},
        {key: 'payments', title: 'Payments', icon: paymentsIcon, component: <Withdrawals/>},
    ]
    return (
        <div className="container">
            <div className="row">
                <div className={classes.nav}>
                    <div className="d-flex align-items-center justify-content-center">
                        {
                            tabs.map((t) => {
                                return <NavItem active={t.key === content.key} key={t.key} value={t} onClick={() => setter({
                                    key: t.key,
                                    component: t.component
                                })}/>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nav