import axios from "axios";
import {retrieveLaunchParams} from "@tma.js/sdk-react";

let authData = 'test';
if (process.env.REACT_APP_DEV_AUTH === undefined || process.env.REACT_APP_DEV_AUTH === false) {
    const { initDataRaw } = retrieveLaunchParams();
    authData = initDataRaw
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});
api.defaults.headers.common['Authorization'] = authData;

const methods = {
    profile() {
        return api.get('/profile')
    },
    rewards(page) {
        return api.get('/rewards', {params: {page}})
    },
    deposits(page) {
        return api.get('/deposits', {params: {page}})
    },
    withdrawals(page) {
        return api.get('/withdrawals', {params: {page}})
    },
    referrals(page) {
        return api.get('/referrals', {params: {page}})
    },
    claimStatus() {
        return api.get('/claim')
    },
    claim() {
        return api.post('/claim')
    },
    withdraw(amount, address) {
        return api.post('/withdraw', {amount, address})
    },
    swap(power) {
        return api.post('/swap', {power})
    },
    top() {
        return api.get('/top')
    },
    spin() {
        return api.post('/spin')
    },
    buyLuckyTicket() {
        return api.post('/ticket/buy')
    },
    getPreviousRewardsPool() {
        return api.get('/previous/rewards/pool')
    }
}

export default methods